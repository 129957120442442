/* website: 4427-skipthedealerships
 * created at 2024-06-07 18:00 by Mfaye
 */

// Import all makes and organization styles files

@import "../utils/icons.scss";
@import "../utils/includes.scss";

// ========
// Fonts
// ========

$fontFamily: 'Open Sans', arial;
$boldFontFamily: 'Open Sans', arial;
$boldFontWeight: 700;
$titleFontFamily: $boldFontFamily;
$titleFontWeight: $boldFontWeight;
$isUppercase: uppercase;

// ========
// Colors
// ========

$textColor: #333333;
$linkColor: #000000;
$labelColor: #aaaaaa;
$formColor: #333333;
$utilColor: #000000;
$priceColor: #4d4d4d;
$lightColor: #aaaaaa;
$btnColorAlpha: #ffffff;
$btnColorBeta: #ffffff;
$btnColorStep: #ffffff;
$titleColor: #16181f;
$titleSecondaryColor: #ffffff;
$subtitleColor: #333333;
$subtitleSecondaryColor: #ffffff;
$successColor: #00b624;
$warningColor: #f7941d;
$errorColor: #ff2841;

$bgPrimaryColor: #ffffff;
$bgSecondaryColor: #f2f2f2;

$bgBtnAlpha: #00b624;
$colorBtnAlpha: #fff;

$bgBtnBeta: #9a9a9a;
$colorBtnBeta: #fff;

$colorBtnOutlineAlpha: #00b624;
$colorBtnOutlineBeta: #000000;

$paddingButtonPrimary: 16px 14px;
$paddingButtonSecondary: 11px 15px;
$borderRadiusButton: 5px;

$borderRadiusForm: 0px;
$borderColorForm: #000;
$borderSizeForm: 1px solid;

$calculatorTabsColor: #16181e;
$calculatorTabsTextColor: #dedede;
$calculatorCloseTabs: #505050;
$calculatorCloseTabsColor: #686869;
$calculatorMainColor: #ffffff;
$calculatorButton: #00b624;

$garageBackground: #ffffff;
$garageTextColor: #333333;
$garageCarNoteColor: #f8f8f8;

$bgTopbarLogin: #21232e;
$colorTopbarLogin: #c8c9cb;

$progressBarTitleBG: #000000;
$progressBarTitleColor: #ffffff;
$progressBarTitleCloseBG: #ffffff;
$progressBarTitleCloseColor: #000000;
$progressBarCloseButtonBG: #000000;
$progressBarCloseButtonColor: #ffffff;

$paymentCalculatorClosedBG: #171921;
$paymentCalculatorClosedColor: #e8e8e8;
$paymentCalculatorPanelBG: #21232E;
$paymentCalculatorTermWrapperBG: #363a4d;
$paymentCalculatorTermWrapperColor: #aaaaaa;
$paymentCalculatorTermSelectedWrapperBG: #ffffff;
$paymentCalculatorTermSelectedColor: #ffffff;
$paymentCalculatorTermBG: #252835;
$paymentCalculatorTermColor: #aaaaaa;

// ========
// Styles
// ========

.widget-sr{
  @include clearfix;
  width: 100%;
  &.makes__occasion{
    font-family: $fontFamily;
    font-size: 14px;
    color: $textColor;
    background-color: $bgPrimaryColor;
    &.Checkout{
      background-color: $bgSecondaryColor
    }
    .listing-tile .listing-tile-drivePowerTrains{
      color: $textColor;
    }
    .catalog{
      background-color: #F2F2F2 !important;
      .vehicle-note__wrapper{
        background: #F2F2F2;
      }
    }
    .listing-used{
      background-color: #FFFFFF !important;
    }
    .listing-tile-note, .listing-tile-note .vehicle-note{
      background: #FFFFFF !important;
    }
    .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper{
      .TransactionSummaryUnit--single.is-validate{
        border-bottom-color: #000000!important;
      }
    }
    .TransactionSummaryUnit--single{
      .validFlag:before{
        background-color: transparent;
        background-image: -webkit-linear-gradient(304deg,transparent 50%,#000000 55%) !important;
      }
    }
    .TransactionProgressBar--progression{
      background-color: #000000 !important;
    }
    .confirm_deposit--icon,
    .confirm_financing--icon{
      border: 1px solid #000000 !important;
      .icon,.icon-Crochet{
        color: #000000 !important;
      }
    }
    .confirm_deposit--title,
    .confirm_financing--title{
      color: #000000;
    }
    .section-checkout-header__progressBar-stage.validated{
      .picto-showroom:first-child{
        color: #000000;
      }
    }
    .Appointment__content .appointment__steps--single.is-validated {
      .icon-calendar{
        color: #000000 !important;
      }
    }
    .doneLabel {
      color: #000000 !important;
      .icon-Crochet{
        color: #000000 !important;
      }
    }

    .message-handler{
      &.warning,
      &.success{
        background-color: #000000 !important;
      }
    }
    .has-success-background{
      background-color: #000000 !important;
    }
    .header-image{
      img{
        width: auto !important;
        max-width: 210px !important;
      }
    }
    .purchase-methods-cash{
      .calculatorColumn{
        .is-util,
        .sr-link{
          color: #ffffff !important;
        }
      }
    }
    .vehicleBanner-personsViewed{
      .amount{
        color: #ffffff !important;
      }
    }
    .listing-tile-image{
      .car-info{
        .car-meta{
          &:first-of-type,
          &:nth-child(2){
            display: none;
          }
        }
      }
    }
    // **********************
    // Header B&B
    // **********************
    @include headerBuildBuy;

    // **********************
    // Barre de progression
    // **********************
    @include progressBar;

    // **********************
    // Formulaires
    // **********************
    @include forms;

    // **********************
    // Par sections
    // **********************
    @include paymentCalculator;
    @include checkout;
    @include sidebarsummary;
    @include login;
    @include garage;
    @include protections;

    @include titles;
    @include buttons;
    @include links;
    @include generics;
    @include baseline;
  }
}
